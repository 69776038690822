import Vue from "vue"
import _mapValues from "lodash/mapValues"
import Sentry from '@/utils/sentry'

const rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/

// aus jquery data
const parseData = data => {
  if (data === "true") {
    return true
  }
  if (data === "false") {
    return false
  }
  if (data === "null") {
    return null
  }
  // Only convert to a number if it doesn't change the string
  if (data === `${+data}`) {
    return +data
  }
  if (rbrace.test(data)) {
    return JSON.parse(data)
  }
  return data
}
const Automount = (componentName, component, extra = {}) => {
  return Array.from(document.querySelectorAll(componentName)).map(el => {
    const data = _mapValues({ ...el.dataset }, d => parseData(d))
    if (typeof extra === "function") {
      extra = extra()
    }
    const app = new Vue({
      ...extra,
      name: componentName,
      el,
      render: h => h(component, { props: { ...data, ...extra.props } }),
    })
    app.name = componentName
    Sentry.createTracingMixins({ trackComponents: true })

    return [el, app]
  })
}

export default Automount
